var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.is_load)?_c('div',[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Trang chủ")]),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/admin/location/lists"}},[_vm._v(" Quản lý Tỉnh/ Thành phố")])],1),_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Thêm mới Tỉnh/ Thành phố")])]),_c('FormValidate',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
return [_c('form',{attrs:{"id":"sendform"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"container"},[_c('ul',{staticClass:"nav nav-tabs",staticStyle:{"display":"none"},attrs:{"role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"data-toggle":"tab","href":"#home"}},[_vm._v("Tiếng Việt")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"data-toggle":"tab","href":"#menu1"}},[_vm._v("English")])])]),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"container tab-pane active",attrs:{"id":"home"}},[_c('br'),_c('div',{},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('strong',[_vm._v("Thông tin chung ")]),_c('small',[_vm._v("Form")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-10"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Tỉnh/Thành phố "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('InputValidate',{attrs:{"name":"Tỉnh/Thành phố","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Form.name),expression:"Form.name"}],staticClass:"form-control",attrs:{"type":"name","name":"name"},domProps:{"value":(_vm.Form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.Form, "name", $event.target.value)}}}),_c('span',{staticClass:"text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-sm-10"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Mã số "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('InputValidate',{attrs:{"name":"Mã số ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Form.code),expression:"Form.code"}],staticClass:"form-control",attrs:{"type":"code","name":"code"},domProps:{"value":(_vm.Form.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.Form, "code", $event.target.value)}}}),_c('span',{staticClass:"text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])])])])])]),_c('div',{staticClass:"container tab-pane fade",attrs:{"id":"menu1"}},[_c('div',{staticClass:"row"})]),_c('div',{staticClass:"card-footer",staticStyle:{"width":"90%","position":"fixed","bottom":"0"}},[(invalid)?_c('button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button"},on:{"click":_vm.ViewAlert}},[_c('i',{staticClass:"fad fa-save"}),_vm._v(" Save ")]):_c('button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"submit"}},[_c('i',{staticClass:"fad fa-save"}),_vm._v(" Save ")]),_c('button',{staticClass:"btn btn-sm btn-warning ml-3",attrs:{"type":"reset","disabled":invalid},on:{"click":_vm.myreset}},[_c('i',{staticClass:"fad fa-save"}),_vm._v(" Reset ")])])])])])]}}],null,false,3503353557)})],1):_c('div',[_c('MyLoadTheme',_vm._l((10),function(k){return _c('MyLoading',{key:k})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }